import React from 'react';
import client1 from '../images/clients/purplepay.png';
import client2 from '../images/clients/zedvance.png';
import client3 from '../images/clients/coresteplogo.png';
import client4 from '../images/clients/pixpay.png';

// const clientImage = {
//     height: '2rem',
//     width: 'auto',
//     mixBlendMode: 'colorBurn'
// }

const Clients = () => {
    return (
        <div className="mt-5 bg-white">

            
            <section data-aos="fade-up items-center">
                {/* <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-logocolor uppercase font-bold">Some of our clients.</h2>
                        <div className='flex justify-center'>
                            <div className='w-20 border-b-4 border-logocolor'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-logocolor">Some of our clients.</h2>
                    </div> */}

                <div className="p-10 " data-aos="fade-in" >
                    
                    <h2 className="my-2 text-center text-2xl text-logocolor uppercase font-bold">Some of our clients.</h2>
                        <div className='flex justify-center'>
                            <div className='w-20 border-b-4 border-logocomplement'></div>
                        </div>


                    
                    {/* <div className="p-10 flex items-center justify-center [&_li]:mx-8 [&_img]:max-w-none">
                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out hover:opacity-100 w-1/6">
                            <img src={client1} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out  hover:opacity-100">
                            <img src={client2} alt="client" />                            
                        </div> 

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out hover:opacity-100">
                            <img src={client3} alt="client" />                            
                        </div> 


                        <div style={clientImage} className= "overflow-hidden flex justify-center transition-all ease-in-out hover:opacity-100 w-1/6">
                            <img src={client1} alt="client" />                            
                        </div>

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out  hover:opacity-100">
                            <img src={client2} alt="client" />                            
                        </div> 

                        <div style={clientImage} className="overflow-hidden p-3 flex justify-center transition-all ease-in-out hover:opacity-100">
                            <img src={client3} alt="client" />                            
                        </div> 
                        

                       
                    </div> */}
                    <div class="flex overflow-hidden space-x-16 group">
  <div class="flex h-20 space-x-16 animate-loop-scroll group-hover:paused">
    <img loading="lazy" src={client1} class="max-w-none " alt="Purplepay" />
    <img loading="lazy" src={client2} class="max-w-none" alt="Zedvance" />
    <img loading="lazy" src={client3} class="max-w-none" alt="Corerstep" />
    <img loading="lazy" src={client4} class="max-w-none" alt="Pixpay" />
  </div>
  <div class="flex h-20 space-x-16 animate-loop-scroll group-hover:paused">
    <img loading="lazy" src={client1} class="max-w-none" alt="Purplepay" />
    <img loading="lazy" src={client2} class="max-w-none" alt="Zedvance" />
    <img loading="lazy" src={client3} class="max-w-none" alt="Corestep" />
    <img loading="lazy" src={client4} class="max-w-none" alt="Pixpay" />
  </div>
  
</div>
                </div>

               
            </section>
           
            
        </div>
    )
}

export default Clients;